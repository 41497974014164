@import "../../../theme/variable.scss";
.add {
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  .iconBox {
    background: $PRIMARY_MAIN;
    padding: 4px 4px 1px 4px;
    border-radius: 4px;
  }
  span {
    color: $NEUTRAL-100;
    font-size: calc(10px + (14 - 10) * ((100vw - 280px) / (1600 - 280)));
    font-weight: 400;
    line-height: 20px;
  }
}
.bodyMotorcycle {
  padding: 24px;
  display: flex;
  .left {
    flex: 1;
    display: flex;
    gap: 32px;
    span {
      width: fit-content;
      color: $NEUTRAL-100;
      font-size: calc(16px + (24 - 16) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
  }

  .right {
    width: 25%;
  }
}

.bodyAdminDetail {
  display: flex;
  padding: 0px 0px 24px 0px;
  border-bottom: 1px solid $NEUTRAL-30;
  .left {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 24px;

    span {
      color: $NEUTRAL-100;

      font-size: calc(16px + (24 - 16) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 116.667% */
    }
  }

  .right {
    display: flex;
    .editBox {
      display: flex;
      padding: 4px 8px;
      align-items: center;
      gap: 4p;
      border-radius: 4px;
      border: 1px solid $NEUTRAL-100;
      background: $PRIMARY_SURFACE;
      color: #357abb;
      .linkBox {
        text-decoration: none;
        color: #357abb;
      }
    }
  }
  .resetButton {
    display: flex;
    padding: 10px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid $NEUTRAL-100;
    background: $NEUTRAL-10;
    margin: 0 5px;
    color: $NEUTRAL-100;
    text-align: center;
    /* Text M/Bold */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
  }
  .saveButton {
    display: flex;
    padding: 10px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: $PRIMARY_MAIN;
    margin: 0 5px;
    color: $NEUTRAL-10;
    text-align: center;
    /* Text M/Bold */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
  }
}

@media (max-width: 1200px) {
  .bodyMotorcycle {
    .right {
      width: 40%;
    }
  }
}

@media (max-width: 650px) {
  .bodyMotorcycle {
    padding: 16px;
    flex-direction: column;
    gap: 16px;
    .right {
      width: 100%;
    }
  }
}

@media (max-width: 307px) {
  .bodyMotorcycle {
    .left {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      .add {
        span {
          font-size: 13px;
        }
      }
    }
  }
}
