// @import "../../../theme/variable.scss";

h3 {
  margin: 0;
}

// .defaultTH{
//   border-bottom: 1px solid #ddd;
//   border-top: 1px solid #ddd;
// }

.tableContainer {
  overflow-x: auto;
  // min-width: 100px;
  // height: 500px;
  // min-height: 550px;
  min-height: 300px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  width: 100%;
  // width: 900px;

  .placeholder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    position: relative;
    top: 100px;
    // top: 350px;
    img {
      width: 60px;
      height: 100px;
      max-inline-size: 100%;
      block-size: auto;
    }

    span {
      min-width: 200px;
      font-size: 16px;
      color: #000000;
    }
  }
  .tableServices {
    border-collapse: collapse;
    width: 120%;
    font-size: 16px;
  }

  .defaultTH {
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
  }
  .defaultTHServices {
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
  }

  .defaultTHServices:first-child {
    width: 200px;
    position: sticky;
    left: 0;
    z-index: 1;
    // width: 50px;
    background-color: #fff;
  }

  .stickyColumnDefault:first-child {
    width: 200px;
    position: sticky;
    left: 0;
    z-index: 1;
    // width: 50px;
    background-color: #fff;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
    // color: $NEUTRAL-100;
    // min-height: 100%;
    // min-height: 300px;
    // position: relative;

    &.salesTable {
      width: 100%;
    }

    // tr > th {
    //   position: sticky;
    //   padding: 0.5rem;
    //   z-index: 2;
    //   top: 0;
    //   background-color: $NEUTRAL-10;
    // }

    // tr  {
    //   left: 0;
    //   position: sticky;
    //   z-index: 3;
    //   background-color: $NEUTRAL-10;
    //   width: 250px;
    //   font-size: 14;
    //   font-weight: 500;
    //   color: #0a0a0a;
    //   text-transform: capitalize;
    //   padding: 16px;
    //   cursor: pointer;

    //   &.withoutCheckbox > :first-of-type {
    //     left: 0;
    //     position: sticky;
    //     z-index: 3;
    //     background-color: $NEUTRAL-10;
    //     width: 250px;
    //     font-size: 14;
    //     font-weight: 500;
    //     color: #0a0a0a;
    //     text-transform: capitalize;
    //     padding: 16px;
    //     cursor: pointer;
    //   }

    //   &.withCheckbox > :first-of-type{
    //     left: 0;
    //     position: sticky;
    //     z-index: 3;
    //     background-color: $NEUTRAL-10;
    //     width: 100px;
    //     font-size: 14;
    //     font-weight: 500;
    //     color: #0a0a0a;
    //     text-transform: capitalize;
    //     padding: 16px;
    //     cursor: pointer;
    //   }
    // }

    // th.stickyColumnDefault,
    // td.stickyColumnDefault {
    //   position: sticky;
    //   left: 0;
    //   z-index: 1;
    //   background-color: white;
    //   width: 100px;
    // }

    .stickyColumn {
      // display: flex;
      // align-items: center;
      position: sticky;
      left: 0;
      z-index: 1;
      width: 50px;
      background-color: #fff;
    }

    // .stickyColumnDefault {
    //   position: sticky;
    //   left: 0;
    //   z-index: 1;
    //   width: 250px;
    //   background-color: $NEUTRAL-10;
    // }

    // tr  {
    //   &.withoutCheckbox {
    //     left: 0;
    //     position: sticky;
    //     z-index: 3;
    //     background-color: $NEUTRAL-10;
    //     width: 250px;
    //     font-size: 14;
    //     font-weight: 500;
    //     color: #0a0a0a;
    //     text-transform: capitalize;
    //     padding: 16px;
    //     cursor: pointer;
    //   }

    //   &.withCheckbox {
    //     left: 0;
    //     position: sticky;
    //     z-index: 3;
    //     background-color: $NEUTRAL-10;
    //     width: 150px;
    //     font-size: 14;
    //     font-weight: 500;
    //     color: #0a0a0a;
    //     text-transform: capitalize;
    //     padding: 16px;
    //     cursor: pointer;
    //   }
    // }

    // tr {
    //         width: 250px;
    //   font-size: 14;
    //   font-weight: 500;
    //   color: #0a0a0a;
    //   text-transform: capitalize;
    //   padding: 16px;
    //   cursor: pointer;
    // }

    tbody {
      .hovered {
        background-color: #d2f3f8;
      }
    }

    .column {
      display: flex;
      align-items: center;
      height: 30px;
      span {
        svg {
          margin-top: 6px;
        }
      }
    }
  }
}

.tableContainerUsers {
  overflow-x: auto;
  min-height: 100%;
  .placeholder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    position: relative;
    top: 100px;
    // top: 350px;
    img {
      width: 60px;
      height: 100px;
      max-inline-size: 100%;
      block-size: auto;
    }

    span {
      min-width: 200px;
      font-size: 16px;
      color: #000000;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;
    // padding: 200px;
    font-size: 16px;
    // color: $NEUTRAL-100;
    // min-height: 100%;
    // min-height: 300px;
    // position: relative;

    &.salesTable {
      width: 100%;
    }

    // tr > th {
    //   position: sticky;
    //   padding: 0.5rem;
    //   z-index: 2;
    //   top: 0;
    //   background-color: $NEUTRAL-10;
    // }

    // tr  {
    //   left: 0;
    //   position: sticky;
    //   z-index: 3;
    //   background-color: $NEUTRAL-10;
    //   width: 250px;
    //   font-size: 14;
    //   font-weight: 500;
    //   color: #0a0a0a;
    //   text-transform: capitalize;
    //   padding: 16px;
    //   cursor: pointer;

    //   &.withoutCheckbox > :first-of-type {
    //     left: 0;
    //     position: sticky;
    //     z-index: 3;
    //     background-color: $NEUTRAL-10;
    //     width: 250px;
    //     font-size: 14;
    //     font-weight: 500;
    //     color: #0a0a0a;
    //     text-transform: capitalize;
    //     padding: 16px;
    //     cursor: pointer;
    //   }

    //   &.withCheckbox > :first-of-type{
    //     left: 0;
    //     position: sticky;
    //     z-index: 3;
    //     background-color: $NEUTRAL-10;
    //     width: 100px;
    //     font-size: 14;
    //     font-weight: 500;
    //     color: #0a0a0a;
    //     text-transform: capitalize;
    //     padding: 16px;
    //     cursor: pointer;
    //   }
    // }

    // th.stickyColumnDefault,
    // td.stickyColumnDefault {
    //   position: sticky;
    //   left: 0;
    //   z-index: 1;
    //   background-color: white;
    //   width: 100px;
    // }

    .stickyColumn {
      // display: flex;
      // align-items: center;
      position: sticky;
      left: 0;
      z-index: 1;
      // width: 50px;
      // padding-right: 20px;
      // padding: 30px 30px 30px 30px;
      padding: 20px;
      background-color: #fff;
      .checkBox {
        position: absolute;
        top: 40%;
        left: 0%;
      }
    }

    // .stickyColumnDefault {
    //   position: sticky;
    //   left: 0;
    //   z-index: 1;
    //   width: 250px;
    //   background-color: $NEUTRAL-10;
    // }

    // tr  {
    //   &.withoutCheckbox {
    //     left: 0;
    //     position: sticky;
    //     z-index: 3;
    //     background-color: $NEUTRAL-10;
    //     width: 250px;
    //     font-size: 14;
    //     font-weight: 500;
    //     color: #0a0a0a;
    //     text-transform: capitalize;
    //     padding: 16px;
    //     cursor: pointer;
    //   }

    //   &.withCheckbox {
    //     left: 0;
    //     position: sticky;
    //     z-index: 3;
    //     background-color: $NEUTRAL-10;
    //     width: 150px;
    //     font-size: 14;
    //     font-weight: 500;
    //     color: #0a0a0a;
    //     text-transform: capitalize;
    //     padding: 16px;
    //     cursor: pointer;
    //   }
    // }

    // tr {
    //         width: 250px;
    //   font-size: 14;
    //   font-weight: 500;
    //   color: #0a0a0a;
    //   text-transform: capitalize;
    //   padding: 16px;
    //   cursor: pointer;
    // }

    tbody {
      .hovered {
        background-color: #d2f3f8;
      }
    }

    .column {
      display: flex;
      align-items: center;
      height: 30px;
      span {
        svg {
          margin-top: 6px;
        }
      }
    }
  }
}

.body {
  font-size: 14px;

  .loading {
    display: flex;
    justify-content: center;
  }
}

table th {
  padding: 12px;
}

table tr:last-child {
  border-bottom: unset;
  padding: 12px;
}

table td,
table tr {
  //   border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  padding: 12px;
}
.linkTable {
  color: #000000;
  text-decoration: none;
}
table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: #000000;
  font-size: calc(14em / 14);
  font-weight: 500;
  cursor: pointer;
  // border-bottom: 1px solid black;
}

.th {
  border: none;
}

.header {
  width: 100%;
  height: 76px;
  //   border-bottom: 1px solid $NEUTRAL-30;
  .control {
    padding: 24px 16px 0px 16px;
  }
  .filter {
    h3 {
      font-size: calc(20em / 14);
      font-weight: 700;
    }
  }
}

// master
.radio {
  height: 24px;
  width: 24px;
  border-radius: 12px;
  border: 1px solid #1dc1dd;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  .activeRadio {
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background-color: #1dc1dd;
  }
}

@media (width <= 1024px) {
  .tableContainer {
    table {
      // width: 100%;
      font-size: 12px;

      .stickyColumn {
        width: 50px;
        min-width: 50px;
      }

      // .stickyColumnDefault {
      //   width: 250px;
      //   background-color: $NEUTRAL-10;
      // }
    }
  }
}

@media (width <= 768px) {
  .tableContainer {
    table {
      // width: 100%;
      font-size: 10px;

      .stickyColumn {
        max-width: 150px;
        // background-color: red;
      }
    }
  }
}

@media (width <= 420px) {
  .tableContainer {
    .placeholder {
      span {
        width: 100px;
        font-size: 14px;
      }
    }
    table {
      width: 150%;
      font-size: 12px;

      tr > :first-of-type {
        width: 150px;
      }
    }
  }
}
