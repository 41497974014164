@import "../../../theme/variable.scss";
.wrapperEmptyHandlerSearch {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 12px;
  height: 100%;
  min-height: 460px;
  img[alt="images-empty"] {
    width: 245.965px;
    height: 138.397px;
    flex-shrink: 0;
  }
  .description {
    font-size: 14px;
    color: $NEUTRAL-90;
  }
}
