.body {
  padding: 32px 32px 32px 0px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  min-height: 100vh;
}

.box {
  // display: flex;
  border-radius: 8px;
  background: var(--neutral-10, #fff);
  height: 100%;
  width: 100%;
  padding: 24px;
}

.detailBox {
  display: flex;
  padding: 24px 24px 24px 24px;
  // border-top: 1px solid var(--neutral-50, #c7c7c7);
  // border-bottom: 1px solid var(--neutral-50, #c7c7c7);
  width: 100%;
  .leftDetail {
    flex: 1;
    // border-right: 1px solid var(--neutral-50, #c7c7c7);
    // height: 550px;
    padding-right: 24px;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .listProduct {
      // display: flex;
      // flex-direction: column;
      // gap: 16px;
      // padding-left: 16px;
      position: absolute;
      margin: 30px 0 0 0;
      display: inline-flex;
      padding: 8px 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      border-radius: 8px;
      background: var(--Neutral-10, #fff);

      /* Product Card Hover */
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
      color: var(--Neutral-100, #212121);

      /* Text S/Regular */
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px; /* 116.667% */
      cursor: pointer;
      // .list {
      //   text-decoration: none;
      //   color: var(--neutral-100, #212121);
      //   // text-align: center;
      //   font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
      //   font-style: normal;
      //   font-weight: 700;
      //   line-height: 24px; /* 150% */
      // }
    }

    .cardProduct {
      // display: flex;
      width: 100%;
      // background: #357abb;
      // position: relative;
      height: 100%;
      margin-top: 15px;
      .judul {
        color: var(--Neutral-100, #212121);
        /* Text M/Regular */
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        overflow-wrap: break-word;
        hyphens: manual;
      }

      .cards {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        height: 630px;
        overflow-y: scroll;
        padding-right: 20px;
      }
    }
  }
  .rightDetail {
    width: 50%;
    padding-left: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-left: 1px solid var(--neutral-50, #c7c7c7);
    height: 100%;
    .detail {
      display: flex;
      flex-direction: column;
      gap: 8px;
      span {
        color: var(--neutral-100, #212121);

        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
      input {
        display: flex;
        padding: 12px 16px;
        width: 100%;
        border-radius: 8px;
        border: 1px solid var(--neutral-50, #c7c7c7);
        opacity: 0.8;
        background: var(--neutral-10, #fff);
      }
    }
    .cardsDetail {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 210px 0;
      position: relative;
      // margin-left: 40%;
      // height: 100px;
      // background: #357abb;
      p {
        font-family: Roboto;
        color: #212121;
        font-size: 16px;
        text-align: center;
        width: 100%;
      }
    }
    .xcardsDetail {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
      // margin-top: 50%;
      // margin-left: 40%;
      text-align: justify;
      padding: 0 0 20px 0;
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      // line-height: 20px; /* 142.857% */
      overflow-wrap: break-word;
      hyphens: manual;
      height: 665px;
      .judul {
        color: var(--Neutral-100, #212121);
        /* Text M/Regular */
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        overflow-wrap: break-word;
        hyphens: manual;
      }
      .ket {
        color: var(--Neutral-80, #424242);

        /* Text M/Regular */
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        padding: 10px 0 20px 0;
        // word-wrap: break-word;
        // display: table-column;
        // overflow-wrap: break-word;
        // hyphens: manual;
        // background: #357abb;
        width: 100%;
        // img {
        //   z-index: -1;
        // }
        .namaFoto {
          font-family: Roboto;
          font-size: 14px;
          font-weight: 700;
          display: flex;
          flex-direction: column;
          // background: #357abb;
        }
        .imgFoto {
          .removePhoto {
            display: none;
          }
        }
        .imgFoto:hover {
          position: relative;
          .removePhoto {
            display: block;
            padding: 2px;
            position: absolute;
            z-index: 9999;
            border-radius: 100%;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 700;
            cursor: pointer;
            width: 25px;
            height: 25px;
            // float: right;
            text-align: center;
            background: red;
            color: #fff;
            margin: -10px 0 0 45%;
            // z-index: 9999;
            animation: fadeIn 1s;
          }
          @keyframes fadeIn {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
        }

        img {
          width: 100%;
        }
      }
      .xket {
        color: var(--Neutral-100, #212121);

        /* Text S/Regular */
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 116.667% */
        padding: 5px 0;
      }
      .bodyCarousel {
        width: 100%;
        display: flex;
        margin: 5px 0;
        // height: 100px;
        // background: #357abb;
        padding-right: 15px;

        .bodyCarouselKiri {
          // overflow-y: scroll;
          // display: flex;
          width: 30%;
          // height: 100px;
          // background: red;
          // .img {
          //   // position: relative;
          //   // display: flex;
          //   // width: 100%;
          // }
        }
        .bodyCarouselKanan {
          display: flex;
          flex-direction: column;
          width: 70%;
          // height: 100px;
          // background: green;
          padding-left: 10px;
        }
      }
      .textUpload {
        color: var(--Primary-Main, #357abb);
        /* Text M/Regular */
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }

      p {
        font-family: Roboto;
        color: #212121;
        font-size: 16px;
        text-align: center;
        width: 100%;
      }
    }
  }
  .title {
    display: flex;
    // align-items: center;
    gap: 8%;
    width: 100%;
    border-bottom: 1px solid #c7c7c7;
    padding: 0 0 18px 0;
    // height: 60px;
    .text {
      // background: #357abb;
      color: var(--Neutral-100, #212121);
      // font-size: 24px;
      font-size: calc(16px + (24 - 16) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 116.667% */
      width: 150px;
    }
    .listButton {
      // background: yellow;
      float: right;
      display: flex;
      justify-content: right;
      // width: 60px;
      margin-left: auto;
      a {
        margin: 2px 5px 0 5px;
        right: auto;
        // float: right;
        text-align: right;
        .iconPlus {
          display: flex;
          background: #357abb;
          border-radius: 4px;
          padding: 5px;
          cursor: pointer;
          // margin: 5px 0;
          // float: right;
        }
        .ellipsis {
          display: flex;
          padding: 5px;
          // float: right;
          cursor: pointer;
          // margin: 3px 0;
        }
      }
    }
    .filterItems {
      // background: blue;
      width: 200px;
      margin-left: -20px;
      margin-right: -20px;
    }
  }
}

.buttonBox {
  display: flex;
  align-items: center;
  padding-top: 24px;
  border-top: 1px solid var(--neutral-50, #c7c7c7);
  .null {
    flex: 1;
  }
  .button {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    .buttonAdd {
      display: flex;
      padding: 12px 16px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: var(--primary-main, #357abb);
      color: var(--neutral-10, #fff);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    }
    .buttonCancel {
      display: flex;
      padding: 12px 16px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid var(--neutral-100, #212121);
      background: var(--neutral-10, #fff);
      color: var(--neutral-100, #212121);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
      text-decoration: none;
    }
  }
}
.removePhoto {
  display: flex;
  text-align: right;
  color: #d1001c;
  cursor: pointer;
}
input {
  display: flex;
  padding: 12px 16px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--neutral-50, #c7c7c7);
  opacity: 0.8;
  background: var(--neutral-10, #fff);
}
textarea {
  display: flex;
  padding: 12px 16px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--neutral-50, #c7c7c7);
  opacity: 0.8;
  background: var(--neutral-10, #fff);
  height: 100px;
}

@media (max-width: 900px) {
  .body {
    padding: 32px 16px;
  }
  .detailBox {
    flex-direction: column;
    gap: 32px;
    .leftDetail {
      border: none;
      height: 100%;
      width: 100%;
      padding-right: 0px;
    }
    .rightDetail {
      width: 100%;
      padding-left: 0px;
      border-left: 0;
    }
  }
}
@media (max-width: 450px) {
  .detailBox {
    .leftDetail {
      .showImagesPreview {
        display: grid;
        span {
          text-align: center;
        }
        img {
          margin: auto;
          width: 30%;
        }
        .removePhoto {
          margin-bottom: 10px;
          margin-left: 50%;
        }
      }
    }
  }
}
