@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eef3f8;
}

* {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// *::-webkit-scrollbar {
//   display: none;
// }
// * {
//   scrollbar-width: 2px;
//   scrollbar-color: #c7c7c7 transparent;
// }
// *::-webkit-scrollbar-track {
//   background-color: transparent;
// }
// *::-webkit-scrollbar-thumb {
//   background-color: #c7c7c7;
// }
// *::-ms-scrollbar-thumb {
//   background-color: #c7c7c7;
// }
// *::-moz-scrollbar-thumb {
//   background-color: #c7c7c7;
// }
// *::-o-scrollbar-thumb {
//   background-color: #c7c7c7;
// }
/* General style for Firefox */
* {
  scrollbar-width: thin; /* Adjust width to 'thin' for Firefox */
  scrollbar-color: #c7c7c7 transparent; /* Color for Firefox */
}

/* Chrome, Safari, and other WebKit-based browsers */
*::-webkit-scrollbar {
  width: 2px; /* Width for vertical scrollbar */
  height: 2px; /* Height for horizontal scrollbar */
}

*::-webkit-scrollbar-track {
  background: transparent; /* Background color of the scrollbar track */
}

*::-webkit-scrollbar-thumb {
  background-color: #c7c7c7; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

/* Optional: To handle Edge and IE11 in a similar fashion */
*::-ms-scrollbar {
  width: 2px; /* Adjust width if supported */
  height: 2px; /* Adjust height if supported */
}

*::-ms-scrollbar-track {
  background: transparent; /* Background color of the scrollbar track */
}

*::-ms-scrollbar-thumb {
  background-color: #c7c7c7; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}
