@import "../../../theme/variable.scss";
.body {
  display: flex;
  justify-content: space-between;
}

.left {
  flex: 1;
  display: flex;
  gap: 16px;
  align-items: center;
  .iconBox {
    padding: 12px;
    border-radius: 32px;
    background: $PRIMARY_MAIN;
    box-shadow: 0px -8px 4px 0px rgba(0, 0, 0, 0.2) inset,
      0px 4px 4px 0px rgba(0, 0, 0, 0.2);
  }

  .titleBox {
    display: flex;
    flex-direction: column;
    gap: 4px;
    span {
      color: $NEUTRAL-100;
      font-size: calc(20px + (24 - 20) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
    .routeBox {
      display: flex;
      align-items: center;
      gap: 16px;
      border-bottom: 1px solid $NEUTRAL-50;
      width: 100%;
      .click {
        color: $NEUTRAL-60;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        cursor: pointer;
      }
      .active {
        color: $NEUTRAL-100;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        border-bottom: 4px solid black;
        cursor: pointer;
      }
    }
  }
}

.right {
  display: flex;
  align-items: center;
  gap: 16px;
  .searchBox {
    width: 100%;
  }
  .profileBox {
    display: flex;
    align-items: center;
    gap: 16px;
    img {
      width: 48px;
      height: 48px;
    }
  }
}

@media (max-width: 570px) {
  .body {
    flex-direction: column;
    gap: 32px;
  }

  .right {
    width: 100%;
  }
}
