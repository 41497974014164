@import "../../../theme/variable.scss";
.wrapperCard {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: calc(32px - 24px) 24px 24px 24px;
  width: 1116px;
  > .card {
    width: 252px;
    height: auto;
    border-radius: 8px;
    background-color: $NEUTRAL-10;
    border: 1px solid $NEUTRAL-40;
    display: flex;
    flex-direction: column;
    .wrapImg {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $PRIMARY_SURFACE;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      height: 159px;
      > img {
        width: 131.201px;
        height: 113px;
        object-fit: contain;
      }
    }
    .wrapDescription {
      padding: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(70% - 34px);
      // height: 224px;
      gap: 10px;
      .between {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        > h4 {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          align-self: stretch;
          overflow: hidden;
          color: $NEUTRAL-100;
          font-size: 16px;
          font-weight: 500;
        }
        .wrapPrice {
          display: flex;
          gap: 5px;
          > span {
            color: $NEUTRAL-100;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            text-decoration: none;
          }
        }
      }
      .status {
        padding: 4px 8px;
        border-radius: 8px;
        border: 1px solid $ALERT_DANGER_MAIN;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        > span {
          font-size: 12px;
          font-weight: 500;
          color: $ALERT_DANGER_MAIN;
        }
        .status[is-active="true"] {
          border-color: #65d072;
          > span {
            color: #65d072;
          }
        }
      }
      .variant {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        > div {
          display: flex;
          width: 100%;
          justify-content: space-between;
          .variantTag {
            font-size: 12px;
            color: $NEUTRAL-70;
          }
          .totalVariant {
            font-size: 12px;
            color: $NEUTRAL-100;
            font-weight: 700;
          }
        }
      }
      .buttonCard {
        display: flex;
        gap: 8px;
        width: 100%;
        div {
          width: 100%;
          padding: 6px 12px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          border: 1px solid $NEUTRAL-100;
          border-radius: 8px;
          &:hover {
            background-color: $PRIMARY_SURFACE;
          }
          > span {
            font-size: 14px;
            font-weight: 700;
          }
          &:last-child {
            background-color: $PRIMARY_MAIN;
            border-color: transparent;
            color: $NEUTRAL-10;
            &:hover {
              background-color: $PRIMARY_HOVERED;
            }
          }
        }
      }
    }
  }
}
