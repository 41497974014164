@import "../../../theme/variable.scss";
.filterPagination {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  .iconPagination {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    cursor: pointer;
    padding: 4px;
    &:hover {
      background-color: $NEUTRAL-30;
      border-radius: 4px;
    }
  }
}

.filterPageBullet {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  border: 1px solid $NEUTRAL-40;
  background-color: $NEUTRAL-10;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  > span {
    font-weight: 400;
    &:hover {
      background-color: $NEUTRAL-30;
      border-radius: 4px;
      padding: 4px 8px;
    }
  }
  &.active {
    border: 0;
    background-color: $PRIMARY_MAIN;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    span {
      color: $NEUTRAL-10;
      font-weight: 700;
      font-size: 14px;
      &:hover {
        background-color: $PRIMARY_MAIN;
        border-radius: 0;
        padding: 0;
      }
    }
  }
}

.pageEllipsis {
  width: 15px;
  height: 20px;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  span {
    transform: scale(2) translateY(-5px);
    word-spacing: normal;
  }
}

.none {
  display: none;
}
