.body {
  padding: 32px 32px 32px 0px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  min-height: 100vh;
}

.box {
  // display: flex;
  border-radius: 8px;
  background: var(--neutral-10, #fff);
  // height: 100%;
  width: 100%;
  padding: 24px;
}

.detailBox {
  display: flex;
  padding: 24px 24px 24px 24px;
  border-top: 1px solid var(--neutral-50, #c7c7c7);
  border-bottom: 1px solid var(--neutral-50, #c7c7c7);
  width: 100%;
  .leftDetail {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #c7c7c7;
    height: 800px;
    width: 50%;
    padding: 0px 25px 0px 10px;
    span {
      color: var(--neutral-100, #212121);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      .detailFile {
        color: var(--Neutral-50, #c7c7c7);
        /* Text S/Regular */
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 116.667% */
      }
    }
    .inputImages {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px 16px;
      border: 1px dashed #212121;
      border-radius: 8px;
      height: 169px;
      width: 100%;
    }
    .imagesBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      gap: 16px;
      border: 1px solid #c7c7c7;
      border-radius: 8px;
      .childImage {
        display: flex;
        align-items: center;
        border: 1px dashed #212121;
        border-radius: 8px;
        padding: 0px 90px;
        gap: 5px;
        img {
          width: 80px;
          height: 50px;
        }
        a {
          padding: 6px 12px;
          border: 1px solid #212121;
          border-radius: 8px;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 700;
          cursor: pointer;
        }
      }
      img {
        width: 60%;
        // height: 200px;
      }
      .h4 {
        font-size: 14px;
        font-weight: 700;
        font-family: "Roboto";
        // position: relative;
        // top: 25px;
        cursor: pointer;
        span {
          color: #357abb;
        }
      }
      .h5 {
        font-size: 14px;
        font-family: "Roboto";
        // position: relative;
        // bottom: 15px;
        cursor: pointer;
        span {
          color: #357abb;
          border-bottom: 1px solid #357abb;
        }
        a {
          color: #357abb;
          border-bottom: 1px solid #357abb;
        }
      }

      p {
        font-size: 10px;
        font-family: "Roboto";
        position: relative;
        color: #6d6d6d;
      }
    }
    .showImagesPreview {
      display: flex;
      align-items: center;
      padding: 12px 16px;
      border: 1px dashed #212121;
      border-radius: 8px;
      gap: 5%;
      width: 100%;
      span {
        font-size: 14px;
        font-family: "Roboto";
        // width: 100%;
      }
      img {
        width: 40%;
        height: 100%;
      }
      .removePhoto {
        padding: 6px 12px;
        border: 1px solid #212121;
        border-radius: 8px;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 700;
        cursor: pointer;
        width: 100%;
        // float: right;
        text-align: center;
      }
    }

    input[type="file"] {
      display: none;
    }
  }
  .rightDetail {
    width: 50%;
    padding-left: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
    border-left: 1px solid var(--neutral-50, #c7c7c7);

    .detail {
      display: flex;
      flex-direction: column;
      gap: 8px;
      span {
        color: var(--neutral-100, #212121);

        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
      input {
        display: flex;
        padding: 12px 16px;
        width: 100%;
        border-radius: 8px;
        border: 1px solid var(--neutral-50, #c7c7c7);
        opacity: 0.8;
        background: var(--neutral-10, #fff);
      }
      label {
        color: var(--neutral-100, #212121);

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        text-align: justify;
      }

      .statusRed {
        padding: 4px 8px;
        display: flex;
        width: 30%;
        justify-content: center;
        border-radius: 8px;
        border: 1px solid var(--alert-success-main, #d1001c);
        background: var(--neutral-10, #fff);
        color: #d1001c;
      }
      .statusGreen {
        padding: 4px 8px;
        display: flex;
        width: 30%;
        justify-content: center;
        border-radius: 8px;
        border: 1px solid var(--alert-success-main, #65d072);
        background: var(--neutral-10, #fff);
        color: #65d072;
      }
    }
  }
}

// @media (max-width: 900px) {
//   .body {
//     padding: 32px 16px;
//   }

//   .detailBox {
//     .rightDetail {
//       .detail {
//         .status {
//           width: 50%;
//         }
//       }
//     }
//   }
// }

.buttonBox {
  display: flex;
  align-items: center;
  padding-top: 24px;
  .null {
    flex: 1;
  }
  .button {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    .buttonAdd {
      display: flex;
      padding: 12px 16px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: var(--primary-main, #357abb);
      color: var(--neutral-10, #fff);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    }
    .buttonCancel {
      display: flex;
      padding: 12px 16px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid var(--neutral-100, #212121);
      background: var(--neutral-10, #fff);
      color: var(--neutral-100, #212121);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
      text-decoration: none;
    }
  }
}
@media (max-width: 900px) {
  .body {
    padding: 32px 16px;
  }
  .detailBox {
    flex-direction: column;
    gap: 32px;
    .leftDetail {
      border: none;
      height: 100%;
      width: 100%;
      padding-right: 0px;
      text-align: center;
    }
    .rightDetail {
      width: 100%;
      padding-left: 0px;
      border-left: 0;
    }
  }
}
@media (max-width: 1390px) {
  .detailBox {
    .leftDetail {
      .showImagesPreview {
        display: grid;
        span {
          text-align: center;
        }
        img {
          margin: auto;
          width: 30%;
        }
        .removePhoto {
          margin-bottom: 10px;
          // margin-left: 50%;
          width: 100%;
        }
      }
    }
  }
}
