// @import "../../../theme/variables.scss";

.container {
  font-family: "Poppins";
  position: relative;
  width: 100%;
  border: 1px solid var(--neutral-100, #212121);
  background: var(--neutral-10, #fff);
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  transition: 0.2s;
  cursor: pointer;
  align-items: center;
}

.containerMotorcycleDropdown {
  font-family: "Poppins";
  position: relative;
  width: 100%;
  border: 1px solid var(--neutral-50, #c7c7c7);
  background: var(--neutral-10, #fff);
  border-radius: 8px;
  padding: 2px 8px;
  display: flex;
  justify-content: space-between;
  transition: 0.2s;
  cursor: pointer;
  align-items: center;
}

.containerColorDisabled {
  background-color: #e9ecef;
}

.activeContainer {
  // border-color: #1dc1dd;
  transition: 0.2s;
}

.options {
  font-family: "Poppins";
  position: absolute;
  width: 100%;
  margin-top: 5px;
  border-radius: 8px;
  background-color: #fff;
  transition: 0.2s;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.dropdownOptions {
  display: flex;
  position: absolute;
  margin-top: 5px;
  z-index: 2;
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
  transition: 0.2s;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.optionsTimes {
  font-family: "Poppins";
  width: 50%;
  height: 200px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.option {
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 400;
  color: #2f3439;
  user-select: none;
}

.optionWrapper:hover {
  background-color: #f8f9fa;
  cursor: pointer;
}

.borderBottom {
  margin: 0 10px;
  // border-bottom: 1px solid $NEUTRAL-50;
}

.valueText {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  user-select: none;
}

.placeholderText {
  color: var(--neutral-70, #575757);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
