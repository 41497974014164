.bodyAlert {
  // display: flex;
  // justify-content: center;
  // align-items: center;

  // flex-direction: column;
  gap: 40px;
  padding: 16px 10%;
  z-index: 999;
  width: 100%;
  // background: red;
  left: 0;
  // margin: auto;
}
.error {
  color: #424242;
  background-color: #f8d7da;
  border-color: #f5c2c7;
  gap: 40px;
  padding: 20px;
  .icon {
    // gap: 40px;
    // padding: 2px;
    border-radius: 100%;
    border: solid 1px;
    margin-right: 5px;
    color: #842029;
  }
}

.success {
  color: #424242;
  border: 1px solid var(--Alert-Success-Border, #b2e7b8);
  background: var(--Alert-Success-Surface, #effff1);
  gap: 40px;
  padding: 20px;
  //styleName: Text L/Regular;
  width: 100%;
  border-radius: 5px;
  .icon {
    // gap: 40px;
    // padding: 2px;
    border-radius: 100%;
    border: solid 1px;
    margin-right: 5px;
    color: #65d072;
  }
}

.warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
  border-radius: 5px;
}
.primary {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
  border-radius: 5px;
}

.secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
  border-radius: 5px;
}

.info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
  border-radius: 5px;
}

// @media (max-width: 540px) {
//   .bodyAlert {
//     // padding: 32px 16px;
//   }
// }
