@import "../../../theme/variable.scss";

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
// input[type="date"]::-webkit-calendar-picker-indicator {
//   display: flex;
//   background-color: salmon;
//   -webkit-appearance: none;
// }
input {
  background-color: $NEUTRAL-10 !important;
}
.container {
  width: 100%;
  height: 100%;
  position: relative;
  .errorInfo {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 98;
    width: 16px;
    height: 16px;
  }
  .errorDesc {
    background-color: $NEUTRAL-10;
    position: absolute;
    bottom: -22px;
    right: 8px;
    z-index: 99;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.167);
    font-size: 12px;
    color: #475569;
  }

  .popover {
    position: absolute;
    top: 28px;
    right: 10px;
    width: 18px;
    height: 16px;
    z-index: 100;
  }
  .iconPercent {
    position: absolute;
    top: 50%;
    right: 10px;
    // padding-right: 32px;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    height: 100%;
    .textPercent {
      color: #000000;

      /* Text M/Regular */
      // font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
    .linePercent {
      border-left: 1px solid #212121;
      height: 50%;
      width: 1px;
      margin-right: 16px;
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 22px;
    padding-left: 32px;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    height: 100%;

    .text {
      font-size: 16px;
      font-weight: 400;
      color: #000000;
    }

    .line {
      border-right: 1px solid #adb5bd;
      height: 50%;
      width: 1px;
      margin-left: 16px;
    }

    .linePhone {
      border-right: 1px solid #6d6d6d;
      height: 50%;
      width: 1px;
      margin-left: 16px;
    }
  }

  .iconQr {
    position: absolute;
    top: 50%;
    left: 4px;
    padding-left: 32px;
    transform: translate(-50%, -50%);
    display: flex;
  }

  .iconSearch {
    position: absolute;
    top: 50%;
    left: 5px;
    padding-left: 32px;
    transform: translate(-50%, -50%);
    display: flex;
  }

  .iconMagnifyingGlass {
    color: #6c757d;
  }

  .iconMagnifyingGlassSearch {
    border-radius: 4px;
    background: var(--neutral-100, #212121);
    padding: 4px;
    color: #fff;
  }

  .input {
    padding: 12px;
    border: 1px solid #ced4da;
    border-radius: 8px;
    background-color: #f8f9fa;
    width: 100%;
    &:disabled {
      background-color: #ced4da;
      cursor: not-allowed;
    }
    // &::after {
    //   content: "";
    //   background-image: url("../../../assets/Images/exclamation-circle.png");
    //   background-position: center;
    //   background-repeat: no-repeat;
    //   background-color: rgb(0, 0, 0);
    //   position: absolute;
    //   z-index: 99;
    //   top: 0;
    //   left: 0;
    //   width: 100px;
    //   height: 200px;
    // }
  }
  .input[is-error="true"] {
    border-color: $ALERT_DANGER_MAIN !important;
  }

  .inputPhone {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid var(--neutral-60, #6d6d6d);
    opacity: 0.8;
    background: var(--neutral-40, #ddd);
    width: 100%;
  }

  .inputPercent {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid $NEUTRAL-60;
    width: 100%;
    &:focus {
      outline: none;
      border: 1px solid #1dc1dd;
    }
  }

  .searchInput {
    padding: 12px;
    border: 1px solid #ced4da;
    border-radius: 16px;
    background: var(--primary-surface, #dee9f4);
    width: 100%;
  }

  .searchInputHistory {
    border-radius: 8px;
    border: 1px solid var(--neutral-40, #ddd);
    background: var(--neutral-10, #fff);
    padding: 6px 16px 6px 6px;
    width: 100%;
  }

  .phone {
    padding: 12px 16px 12px 76px;
  }

  // .percent {
  //    padding: 12px 16px 12px 25px;
  // }

  .qr {
    padding: 12px 16px 12px 36px;
  }

  .search {
    padding: 12px 16px 12px 40px;
  }

  .input:focus {
    outline: none !important;
    border-width: 1px;
    border-style: solid;
    border-color: #1dc1dd;
  }

  .searchInput:focus {
    outline: none !important;
    border-width: 1px;
    border-style: solid;
    border-color: #1dc1dd;
  }
  .searchInputHistory:focus {
    outline: none !important;
    border-width: 1px;
    border-style: solid;
    border-color: #1dc1dd;
  }

  li {
    padding: 10px;
    background-color: #f3f3f3;
    margin-bottom: 5px;
    cursor: pointer;
  }

  li:hover {
    background-color: #eaeaea;
  }
}
.wrapperDropdown {
  border: 1px solid $NEUTRAL-30;
}
.dropdown {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  .errorInfo {
    position: absolute;
    top: 14px;
    right: 36px;
    z-index: 98;
    width: 16px;
    height: 16px;
  }
  .errorDesc {
    background-color: $NEUTRAL-10;
    position: absolute;
    bottom: -22px;
    right: 30px;
    z-index: 96;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.167);
    font-size: 12px !important;
    color: #475569 !important;
  }

  .popover {
    position: absolute;
    top: 30px;
    right: 34px;
    width: 18px;
    height: 16px;
    z-index: 100;
  }
  .wrapper {
    display: flex;
    height: 44px;
    border: 1px solid #ced4da;
    border-radius: 8px;
    align-items: center;
    padding: 12px;
    justify-content: space-between;
    cursor: pointer;
    span {
      color: $NEUTRAL-70;
      font-size: 14px;
    }
    span[ischooseitems="true"] {
      color: $NEUTRAL-100;
    }
    .chevron {
      transition: 0.3s;
      transform: rotate(80deg);
      color: #adb5bd;
    }
    .chevron[isopen="true"] {
      transform: rotate(-90deg);
      color: #000000;
    }
    .chevron[isopen="false"] {
      transform: rotate(90deg);
    }
    &:hover {
      transition: 0.5s;
    }
  }
  .wrapper[ischooseitems="true"] {
    border: 1px solid $NEUTRAL-30;
  }
  .wrapper[isleft="true"] {
    justify-content: flex-start;
    gap: 10px;
    span {
      order: 2;
    }
    .chevron {
      order: 1;
    }
  }
  .wrapper[isopen="true"] {
    border: 1px solid #1dc1dd;
    transition: none;
  }
  .wrapper[isdisabled="true"] {
    cursor: not-allowed;
    background-color: $NEUTRAL-20;
    .chevron {
      color: $NEUTRAL-50;
    }
    &:hover {
      outline-color: $NEUTRAL-30;
    }
  }
  .wrapper[is-empty="true"] {
    background-color: #f9fafb;
    border: 1px solid $ALERT_DANGER_MAIN;
  }
  .dropdownItems {
    border-radius: 8px;
    margin-top: 5px;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -0%);
    position: absolute;
    width: inherit;
    border: 1px solid $NEUTRAL-30;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
    background-color: $NEUTRAL-10;
    z-index: 3;
    ul li {
      list-style-type: none;
      border-bottom: 1px solid $NEUTRAL-30;
      padding: 8px 12px;
      cursor: pointer;
      font-size: 1rem;
      &:hover {
        background-color: $NEUTRAL-30;
      }
    }
    ul li:nth-last-child(1) {
      border: none;
    }
  }
  .dropdownItems[isOpen="true"] {
    opacity: 1;
    visibility: visible;
  }
}
