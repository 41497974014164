.titleComponent h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: white;
  font-family: Roboto;
}

.titleAdmin h1 {
  font-size: 24px;
  font-family: Roboto;
}
.titleAdmin {
  flex: 1;
  display: flex;

  .left {
    flex: 1;
    display: flex;
    // font-size: 24px;
    font-size: calc(16px + (24 - 16) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 700;

    .h1 {
      font-size: 24px;
      font-family: Roboto;
    }
  }

  .right {
    display: flex;
    // .h1 {
    //   /* Text L/Regular */
    //   font-family: Roboto;
    //   font-size: 16px;
    //   font-style: normal;
    //   font-weight: 400;
    //   text-decoration: none;
    //   line-height: 24px; /* 150% */
    .linkBox {
      text-decoration: none;
      color: var(--Neutral-100, #212121);
      /* Text L/Regular */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      padding: 22px 0;
      cursor: pointer;
      margin-left: 10px;
      // margin-right: 10px;
      display: flex;
    }
    .xlinkBox {
      text-decoration: none;
      color: var(--Neutral-100, #212121);
      /* Text L/Regular */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      // padding: 22px 0;
      cursor: pointer;
      margin-left: 20px;
      // margin-right: 10px;
      display: flex;

      line-height: 28px; /* 116.667% */
      .linkIcon {
        width: 20px;
        height: 20px;
        line-height: 24px; /* 150% */
        margin: 5px;
      }
    }
    .cancelButton {
      display: flex;
      width: 66px;
      height: 30px;
      padding: 6px 12px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid var(--Neutral-100, #212121);
      background: var(--Neutral-10, #fff);
      margin: 0 5px;
      cursor: pointer;
    }
    .saveButton {
      display: flex;
      padding: 6px 12px;
      height: 30px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: var(--Primary-Main, #357abb);
      margin: 0 5px;
      color: #fff;
      cursor: pointer;
    }
  }
}
.motorCardTitle {
  display: flex;
  flex-direction: column;

  // margin: 20px 0;
  padding: 0px 50px;
  // background-color: white;
  .h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    font-family: Roboto;
  }
  .p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.titleCart {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}

.titleCart h1 {
  font-size: 20px;
  // margin-top: 10%;
}

.titleCart p {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  //  left: 20%;
}
