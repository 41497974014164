@import "../../../theme/variable.scss";

.wrapperLoading {
  background-color: #eef3f7;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .loadSpinner {
    border: 8px solid $PRIMARY_SURFACE;
    border-left-color: $PRIMARY_MAIN;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 0.8s infinite linear;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}
