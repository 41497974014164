@import "../../../theme/variable.scss";
.wrapperEmpty {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  row-gap: 12px;
  min-height: 460px;
  img[alt="is-empty-images"] {
    width: 288px;
    height: 194px;
  }
  > h4 {
    font-size: 14px;
    font-weight: 400;
    color: $NEUTRAL-90;
  }
  .buttonAddPromo {
    border: 1px solid $NEUTRAL-50;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 1rem;
    color: $NEUTRAL-100;
    cursor: pointer;
    > h6 {
      color: $NEUTRAL-90;
      font-weight: 500;
    }
    &:hover {
      background-color: $NEUTRAL-20;
    }
  }
}
