.ProductCardImg {
  width: 140px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 8px;
  // background: url(<path-to-image>) lightgray 50% / cover no-repeat;
}
.card {
  background: white;
  padding: 8px 8px 8px 8px;
  border: 1px solid #c7c7c7;
  border-radius: 16px;
  display: flex;
  gap: 3%;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
  width: 100%;
}
.xcard {
  padding: 8px 8px 8px 8px;
  border-radius: 16px;
  display: flex;
  gap: 3%;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
  border: 3px solid var(--Primary-Main, #357abb);
  background: var(--Primary-Surface, #d7e4f1);
}
.titleProductCard {
  width: 100%;
  padding: 0px;
  .titleProductCardjudul {
    height: 48px;
    align-self: stretch;
    color: var(--Neutral-100, #212121);

    /* Text L/Medium */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
  .titleProductCardket {
    align-self: stretch;
    color: var(--Neutral-60, #6d6d6d);

    /* Text S/Regular */
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 116.667% */
  }
}

.iconPanah {
  display: flex;
  width: 100%;
  cursor: pointer;
  margin-right: 0;
  float: right;
}
