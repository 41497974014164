span[navigate="true"] {
  cursor: pointer;
}
.body {
  padding: 32px 32px 32px 0px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  // width: 100vw;
  // min-height: 100vh;
  max-width: 1440px;
  width: 100%;
  height: 100%;
}

.box {
  border-radius: 8px;
  background: var(--neutral-10, #fff);
  //   height: 100%;
  //   height: 100%;
  width: 100%;
}

.pagination {
  padding: 24px;
  display: flex;
  width: 100%;
  .paginationLeft {
    // padding: 24px;
    display: flex;
    width: 100%;
  }
  .paginationRight {
    display: flex;
  }
}
.statusGreen {
  display: flex;
  width: 88px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--Alert-Success-Main, #65d072);
  color: #65d072;
}
.statusRed {
  display: flex;
  width: 88px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--Alert-Success-Main, #d1001c);
  color: #d1001c;
}

.btnSuccess {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #ffffff;
  background-color: #73a839;
  border-color: #73a839;
  text-decoration: none;
  margin: 0 5px;
}
.btnSuccess:focus,
.btnSuccess:active:focus,
.btn.active:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
  color: #ffffff;
  background-color: #59822c;
  border-color: #547a29;
}
.btnInfo {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #ffffff;
  background-color: #033c73;
  border-color: #033c73;
  margin: 0 5px;
}
.btnInfo:focus,
.btnInfo:active:focus,
.btn.active:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
  color: #ffffff;
  background-color: #022241;
  border-color: #011d37;
}
.btnDanger {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #ffffff;
  background-color: #c71c22;
  border-color: #c71c22;
  margin: 0 5px;
}
.btnDanger:focus,
.btnDanger:active:focus,
.btn.active:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
  color: #ffffff;
  background-color: #9a161a;
  border-color: #911419;
}

@media (max-width: 900px) {
  .body {
    padding: 32px 16px;
  }
}
