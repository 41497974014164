$PRIMARY_PRESSED: #23517d;
$PRIMARY_MAIN: #357abb;
$PRIMARY_HOVERED: #2c669c;

$PRIMARY_SURFACE: #dee9f4;
// $NEUTRAL_10: #ffffff;
// $NEUTRAL_20: #f5f5f5;
// $NEUTRAL_80: #424242;
// $NEUTRAL_100: #212121;
$ALERT_DANGER_MAIN: #d1001c;
$ALERT_DANGER_SURFACE: #fff2f4;
$NEUTRAL_70: #6d6d6d;
$PRIMARY-60: #1dc1dd;
$NEUTRAL-10: #ffffff;
$NEUTRAL-20: #f8f9fa;
$NEUTRAL-30: #e9ecef;
$NEUTRAL-40: #dee2e6;
$NEUTRAL-50: #ced4da;
$NEUTRAL-60: #adb5bd;
$NEUTRAL-70: #6c757d;
$NEUTRAL-80: #495057;
$NEUTRAL-90: #2f3439;
$NEUTRAL-100: #000000;
$NEUTRAL-BASE-100: #212121;

$NEUTRAL_OVERLAY: #00000080;
$SENTIMENT_NEGATIVE_PRIMARY: #e13b3b;
$SENTIMENT_POSITIVE_PRIMARY: #549434;
$PRIMARY-10: #d2f3f8;
$PRIMARY-50: #43cbe3;
$PRIMARY-60: #1dc1dd;
$PRIMARY-70: #18a1b8;
$PRIMARY-100: #0a404a;
// $SECONDARY_MAIN: #27bb83;
// $PRIMARY_LINEAR: linear-gradient(104.04deg, #1571de 0%, #4b92f1 100%);
// $PRIMARY_DISABLED: #91bdf1;
// $PRIMARY_SURFACE_HOVER: #f1f3ff95;
// $SECONDARY_SOFT: #eafef3;
// $NEUTRAL_LIGHTEST: #ffffff;
// $NEUTRAL_LIGHTER: #fafafa;
// $DANGER_MAIN: #D1001C;
// $NEUTRAL_30: #ededed;
// $NEUTRAL_40: #e0e0e0;
// $NEUTRAL_50: #c2c2c2;
// $NEUTRAL_60: #9e9e9e;
// $NEUTRAL_90: #404040;
// $NEUTRAL_DARKEST: #000000;
// $DANGER_OUTLINE_BORDER: #fc86a2;
// $DANGER_SURFACE: #faeff2;
// $DANGER_SOFT: #fff4f2;
// $NEUTRAL_BOX_SHADOW: #035fcb24;
// $NEUTRAL_OVERLAY: #00000080;
// $NEUTRAL_BACKGROUND: #f4f4f4;
// $NEUTRAL_LINE: 1px solid #e1d9d9;
// $TEXTAREA_ICON_BG: #f5f6f8;

// $PICTON_BLUE: #51cbee;
// $BORDER_INPUT_ONFOCUS: 1px solid #51cbee;
// $BOX_SHADOW_INPUT_ONFOCUS:  0 0 5px #51cbee;

// $DARK_CHARCOAL: #333333;
// $PLATINUM: #ece6e6;
// $CHINESE_BLACK: #0f161f;
// $TAUPE_GRAY: #878787;
// $ALICE_BLUE: #f3f9ff;
// $PLATINUM_SOLID: #e8e8e8;

// $ACTIVE_MAIN: #96ab12;
// $ACTIVE_SURFACE: #fef8f0;
// $SUCCESS_MAIN: #27bb83;
// $ALERT_SURFACE:  #fff9e9;
