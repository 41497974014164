.modalBackground {
  width: 100%;
  height: 1020px;
  // backdrop-filter: blur(1px);
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  z-index: 9999;

  .modalContainer {
    width: 40%;
    background-color: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    // top: 0;
    // margin: 0px auto;
    // border: 1px solid black;
    // margin-top: 10px;
    .inputAddPromo {
      form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .buttonSave {
          // margin-left: 69%;
          text-align: right;
          float: right;
        }
        .inputTitlePromo {
          display: flex;
          flex-direction: column;
          gap: 10px;
          label {
            font-family: Roboto;
            font-size: 14px;
          }
          input {
            width: 100%;
            height: 44px;
            border: 1px solid #212121;
            border-radius: 8px;
            padding: 12px 16px;
            color: var(--Neutral-60, #6d6d6d);
          }
          textarea {
            width: 100%;
            height: 100px;
            border: 1px solid #212121;
            border-radius: 8px;
            padding: 12px 16px;
            color: var(--Neutral-60, #6d6d6d);
          }
        }

        .inputImagePromo {
          display: flex;
          flex-direction: column;
          gap: 10px;
          label {
            font-family: Roboto;
            font-size: 14px;
          }
          input[type="file"] {
            display: none;
          }
          a {
            .buttonUpload {
              p {
                font-size: 14px;
                font-family: Roboto;
                color: white;
                font-weight: bold;
                cursor: pointer;
              }
            }
          }
          .showImagesPreview {
            display: flex;
            align-items: center;
            padding: 12px 16px;
            border: 1px dashed #212121;
            border-radius: 8px;
            gap: 5%;
            width: 100%;
            span {
              font-size: 14px;
              font-family: "Roboto";
              // width: 100%;
            }
            img {
              width: 40%;
              height: 100%;
            }
            .removePhoto {
              padding: 6px 12px;
              border: 1px solid #212121;
              border-radius: 8px;
              font-family: Roboto;
              font-size: 14px;
              font-weight: 700;
              cursor: pointer;
              width: 100%;
              // float: right;
              text-align: center;
            }
          }

          input[type="file"] {
            display: none;
          }
          .inputImages {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px 16px;
            border: 1px dashed var(--Neutral-50, #c7c7c7);
            border-radius: 8px;
            // height: 169px;
            p {
              color: var(--Neutral-60, #6d6d6d);
              text-align: center;

              /* Text S/Regular */
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px; /* 116.667% */
            }
            .ket2 {
              color: var(--Neutral-100, #212121);
              text-align: center;

              /* Text S/Regular */
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px; /* 116.667% */
              a {
                color: #357abb;
                cursor: pointer;
                border-bottom: 1px solid #357abb;
              }
            }
            .ket1 {
              color: var(--Neutral-100, #212121);
              text-align: center;

              /* Text L/Bold */
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px; /* 150% */
              span {
                color: #357abb;
              }
            }
            .iconImages {
              margin: 10px 0;
            }
          }
          .hoverUpload {
            background: #357abb;
            width: 100%;
            // height: 100px;
            display: flex;
            // flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            border: 1px dashed var(--Primary-Border, #9abcdd);
            opacity: 0.8;
            background: var(--Primary-Main, #357abb);
            padding-bottom: 25px;
            .iconLeft {
              padding-top: 50px;
            }
            .iconCenter {
              margin: 40px;
              display: flex;
              flex-direction: column;
              text-align: center;
              justify-content: center;
              align-items: center;
              color: var(--Neutral-10, #fff);
              text-align: center;

              /* Text L/Bold */
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px; /* 150% */
              padding-top: 25px;
            }
            .iconRight {
              // margin-top: -100px;
            }

            .iconImages {
              margin: 10px 0;
              border: 1px solid #fff;
              border-radius: 5px;
            }
          }
        }
      }
    }
    .titleModal {
      width: 100%;
      // background: #357abb;
      display: flex;
      gap: 57%;
      .buttonClose {
        // background: red;
        margin-left: auto;
        cursor: pointer;
      }
      .titlePromo {
        // background: yellowgreen;
        position: relative;
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 0%;
        h1 {
          color: var(--Neutral-100, #212121);

          /* H4/Bold */
          font-family: Roboto;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          // line-height: 28px; /* 116.667% */
          width: 120%;
          // background: red;
          margin: 0;
        }
        p {
          color: var(--Neutral-70, #6d6d6d);
          /* Text S/Regular */
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          margin: 0 0 50px 0;
          // line-height: 14px; /* 116.667% */
          // position: relative;
          // bottom: 30%;
          // flex-direction: column;
          // width: 100%;
          width: 120%;
          // background: yellow;
        }
      }
    }
    // .titleModal {
    //   display: flex;
    //   // flex-direction: column;
    //   gap: 57%;
    //   width: 100%;
    //   // height: 100px;
    //   .buttonClose {
    //     cursor: pointer;
    //     display: flex;
    //     padding: 10px;
    //     background: #6d6d6d;
    //     display: flex;
    //     width: 100%;
    //   }
    //   .titlePromo {
    //     width: 100%;
    //     display: flex;
    //     flex-direction: column;
    //     gap: 0%;
    //     background-color: #357abb;

    //     h1 {
    //       // display: flex;
    //       font-family: Roboto;
    //       font-size: 15px;
    //       // position: relative;
    //       // bottom: 15%;
    //       width: 100px;
    //       background: red;
    //     }
    //     p {
    //       font-family: Roboto;
    //       font-size: 12px;
    //       position: relative;
    //       bottom: 30%;
    //       // flex-direction: column;
    //       // width: 100%;
    //       width: 150px;
    //       background: yellow;
    //     }
    //   }
    // }
  }
}
@media (max-width: 1200px) {
  .modalBackground {
    height: 1840px;
    .modalContainer {
      width: 70%;
    }
  }
}
@media (max-width: 750px) {
  .modalBackground {
    .modalContainer {
      width: 95%;
    }
  }
}
@media (max-width: 550px) {
  .modalBackground {
    height: 2000px;
    .modalContainer {
      width: 95%;
    }
  }
}
