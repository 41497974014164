@import "../../../../theme/variable.scss";

@mixin inputAndLabel() {
  .uploadDetail {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    width: 100%;
    > span {
      font-size: 14px;
      > span {
        color: $ALERT_DANGER_MAIN;
      }
    }
    .wrapperUpload {
      padding: 10px 1rem;
      border: 1px dashed $NEUTRAL-50;
      border-radius: 8px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      row-gap: 1rem;
      height: 280px;
      .btnUpload {
        padding: 12px 1rem;
        border-radius: 8px;
        display: flex;
        gap: 4px;
        background-color: $PRIMARY_MAIN;
        color: $NEUTRAL-10;
        cursor: pointer;
        align-items: center;
        font-weight: 700;
        .icon {
          width: 24px;
          height: 24px;
          flex-shrink: 0;
        }
      }
      .description {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 224px;
        text-align: center;
        span {
          font-size: 16px;
          font-weight: 700;
          span {
            color: $PRIMARY_MAIN;
          }
        }
        p {
          color: $NEUTRAL-50;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
}
@mixin labelInput() {
  .labelInput {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
}
@mixin addButton($background, $color) {
  display: flex;
  justify-content: center;
  width: 100%;
  border: 1px solid $NEUTRAL-30;
  border-radius: 8px;
  padding: 12px 0;
  gap: 4px;
  color: $color;
  cursor: pointer;
  font-weight: 500;
  background-color: $background;
  .icon {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    color: $color;
  }
}
@mixin wrapper($direction) {
  margin-inline: 1rem;
  padding: 24px;
  display: flex;
  flex-direction: $direction;
  row-gap: 12px;
  border: 1px solid $NEUTRAL-30;
  border-radius: 8px;
}
@mixin flexColumn($gap) {
  display: flex;
  flex-direction: column;
  row-gap: $gap;
}
@mixin textArea() {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  > label {
    display: flex;
    gap: 4px;
    font-size: 14px;
    span {
      color: $ALERT_DANGER_MAIN;
    }
  }
  .wrapTextArea {
    position: relative;
    > textarea {
      resize: none;
      border-color: $NEUTRAL-50;
      height: 112px;
      font-size: 14px;
      &:focus {
        outline: 1px solid #1dc1dd;
      }
    }
    > textarea[is-error="true"] {
      border-color: $ALERT_DANGER_MAIN;
    }
    .errorInfo {
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 98;
      width: 16px;
      height: 16px;
    }
    .errorDesc {
      background-color: $NEUTRAL-10;
      position: absolute;
      bottom: 46px;
      right: 8px;
      z-index: 99;
      padding: 8px;
      border-radius: 8px;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.167);
      font-size: 12px;
      color: #475569;
    }

    .popover {
      position: absolute;
      top: 28px;
      right: 10px;
      width: 18px;
      height: 16px;
      z-index: 100;
    }
  }
}
.body {
  max-width: 1124px;
  .wrapperModalError {
    padding: 24px 1rem;
    @include flexColumn(2rem);
    > .containError {
      @include flexColumn(10px);
      align-items: center;
      > img {
        width: 60px;
        height: 60px;
      }
      h1 {
        color: $NEUTRAL-90;
        font-size: 24px;
        font-weight: 700;
      }
      > p {
        color: $NEUTRAL-60;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
      }
    }
    .btnModalError {
      padding: 10px 12px;
      border-radius: 8px;
      border: 1px solid $NEUTRAL-100;
      display: flex;
      justify-content: center;
      color: $NEUTRAL-100;
      cursor: pointer;
      &:hover {
        background-color: $NEUTRAL-30;
      }
      > span {
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
  .loadingPost {
    padding: 24px 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
    .lottie {
      width: 100%;
      height: 100%;
    }
    .alert {
      font-size: 24px;
      font-weight: 700;
    }
    .description {
      color: $NEUTRAL-60;
      font-size: 14px;
      text-align: center;
    }
  }
  padding: 32px 32px 32px 0px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  min-height: 100vh;
  .mainContent {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding-bottom: 1rem;
    background-color: $NEUTRAL-10;
    border-radius: 8px;
    position: fixed;
    overflow: scroll;
    top: calc(10% + 2rem);
    width: 76%;
    height: 80%;
    // right: 2rem;
    max-width: 1124px;
    .header {
      display: flex;
      align-items: center;
      gap: 1rem;
      border-bottom: 1px solid $NEUTRAL-30;
      padding: 1rem;
      width: 100%;
      justify-content: space-between;
      .backHeader {
        display: flex;
        align-items: center;
        gap: 1rem;
        > span {
          display: flex;
          padding: 8px;
          cursor: pointer;
          .iconBack {
            width: 24px;
            height: 24px;
            flex-shrink: 0;
            transform: rotate(180deg);
          }
        }
        > h1 {
          font-size: 24px;
        }
      }
      .buttonCta {
        display: flex;
        gap: 8px;
        > button:nth-child(1),
        > button:nth-child(2) {
          border: none;
          padding: 10px 12px;
          display: flex;
          align-items: center;
          border-radius: 8px;
          cursor: pointer;
          width: fit-content;
          height: fit-content;
        }
        > button:nth-child(1)[is-disabled="true"],
        button:nth-child(2)[is-disabled="true"] {
          cursor: not-allowed;
        }
        > button:nth-child(1) {
          color: $NEUTRAL-100;
          border: 1px solid $NEUTRAL-90;
          background-color: $NEUTRAL-10;
          &:hover {
            background-color: $NEUTRAL-20;
          }
          > span {
            font-size: 14px;
            font-weight: 700;
          }
        }
        > button:nth-child(2) {
          background-color: $PRIMARY_MAIN;
          color: $NEUTRAL-10;
          border: 1px solid $PRIMARY_MAIN;
          &:hover {
            background-color: $PRIMARY_HOVERED;
          }
          &:active {
            background-color: $PRIMARY_PRESSED;
          }
          span {
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
    }
    .header[is-sticky="true"] {
      position: sticky;
      top: 0;
      z-index: 999;
      background-color: $NEUTRAL-10;
    }
    .formDetailMotor {
      margin-inline: 1rem;
      padding: 24px;
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      border: 1px solid $NEUTRAL-40;
      border-radius: 8px;
      > h2 {
        font-size: 24px;
        font-weight: 700;
      }
      .thumbnailForm {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 24px;
        width: 100%;
        .uploadDetail {
          display: flex;
          flex-direction: column;
          row-gap: 4px;
          > span {
            font-size: 14px;
            > span {
              color: $ALERT_DANGER_MAIN;
            }
          }
          .wrapperUpload {
            padding: 10px 1rem;
            border: 1px dashed $NEUTRAL-50;
            border-radius: 8px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            row-gap: 1rem;
            height: 100%;
            width: 362px;
            .btnUpload {
              padding: 12px 1rem;
              border-radius: 8px;
              display: flex;
              gap: 4px;
              background-color: $PRIMARY_MAIN;
              color: $NEUTRAL-10;
              cursor: pointer;
              align-items: center;
              font-weight: 700;
              &:hover {
                background-color: $PRIMARY_HOVERED;
              }
              &:active {
                background-color: $PRIMARY_PRESSED;
              }
              .icon {
                width: 20px;
                height: 20px;
                flex-shrink: 0;
              }
            }
            .description {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 224px;
              text-align: center;
              span {
                font-size: 16px;
                font-weight: 700;
                span {
                  color: $PRIMARY_MAIN;
                }
              }
              p {
                color: $NEUTRAL-50;
                font-size: 12px;
                font-weight: 400;
              }
              p[is-error="true"] {
                color: $ALERT_DANGER_MAIN;
              }
            }
            > img[alt="motor"] {
              object-fit: contain;
              width: calc(362px - 1rem);
              height: calc(279px - 10px);
            }
          }
          .wrapperUpload[is-field-image="true"] {
            padding: 0px 2px;
          }
          .wrapperUpload[is-error="true"] {
            border-color: $ALERT_DANGER_MAIN;
          }
          .wrapperUpload[is-empty="true"] {
            border-color: $ALERT_DANGER_MAIN;
          }
        }
        .inputDetail {
          display: flex;
          flex-direction: column;
          row-gap: 1rem;
          width: 100%;
          .labelInput {
            display: flex;
            flex-direction: column;
            row-gap: 4px;
            > label {
              font-size: 14px;
              span {
                color: $ALERT_DANGER_MAIN;
              }
            }
          }
        }
        .textArea {
          @include textArea();
        }
      }
    }

    .divider {
      margin-block: 2rem;
      background-color: $NEUTRAL-50;
      width: calc(100% - 2rem);
      margin-inline: 1rem;
      padding: 0.5px;
    }
    .varianMotor {
      padding: 24px;
      border: 1px solid $NEUTRAL-40;
      border-radius: 8px;
      margin-inline: 1rem;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      .between {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        height: fit-content;

        > h2 {
          font-size: 24px;
          font-weight: 700;
          margin: 0;
        }
        > .icon {
          width: 24px;
          height: 24px;
          cursor: pointer;
          flex-shrink: 0;
          color: $NEUTRAL-60;
          &:hover {
            color: $ALERT_DANGER_MAIN;
          }
        }
      }
      .divider {
        height: 1px;
        margin-inline: 0;
        width: 100%;
        margin-block: 12px;
      }
      .inputDetail {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        width: 100%;
        .rowInput {
          display: flex;
          gap: 1rem;
          width: 100%;
        }
        .labelInput {
          width: 100%;
          display: flex;
          flex-direction: column;
          row-gap: 4px;
          label {
            color: $NEUTRAL-100;
            font-size: 14px;
            span {
              color: $ALERT_DANGER_MAIN;
            }
          }
        }
      }
      .lastPrice {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
      }
      .addVariant {
        display: flex;
        gap: 1rem;
        width: 100%;
        align-items: flex-end;
        .uploadVariant {
          display: flex;
          flex-direction: column;
          width: 239px;
          row-gap: 4px;
          > div {
            display: flex;
            gap: 4px;
            .icon {
              width: 16px;
              height: 16px;
              flex-shrink: 0;
              cursor: pointer;
              color: $ALERT_DANGER_MAIN;
            }
            Span {
              font-size: 14px;
            }
          }
        }
        .inputUpload {
          border: 1px dashed $NEUTRAL-50;
          padding-inline: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          width: 100%;
          flex-direction: column;
          row-gap: 1rem;
          text-align: center;
          height: auto;
          height: 136px;
          .button {
            display: flex;
            padding: 6px 12px;
            border-radius: 8px;
            background-color: $PRIMARY_MAIN;
            color: $NEUTRAL-10;
            font-weight: 700;
            gap: 8px;
            cursor: pointer;
            font-size: 14px;
            align-items: center;
            &:hover {
              background-color: $PRIMARY_HOVERED;
            }
            &:active {
              background-color: $PRIMARY_PRESSED;
            }
            .icon {
              width: 14px;
              height: 14px;
              flex-shrink: 0;
              color: $NEUTRAL-10;
            }
          }
          > span {
            font-size: 12px;
            color: $NEUTRAL-50;
            width: 224px;
          }
        }
        .inputUpload[is-error="true"] {
          border-color: $ALERT_DANGER_MAIN;
          > span {
            color: $ALERT_DANGER_MAIN;
          }
        }
        .inputUpload[is-choose-file="true"] {
          padding: 4px;
          > img[alt="color-motor"] {
            width: calc(239px - 8px);
            height: calc(126.5px - 10px);
            object-fit: contain;
          }
        }
        .inputUpload[is-empty="true"] {
          border-color: $ALERT_DANGER_MAIN;
        }
        .inputVariant {
          width: 100%;
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          @include labelInput();
          > .labelInput {
            label {
              font-size: 14px;
              span {
                color: $ALERT_DANGER_MAIN;
              }
            }
          }
        }
      }
      .addColorAndVariant {
        @include addButton(white, #23517d);
        &:hover {
          background-color: $PRIMARY_SURFACE;
        }
      }
    }
    .addBtnVariant {
      @include addButton($PRIMARY_MAIN, white);
      margin-inline: 1rem;
      width: calc(100% - 2rem);
      &:hover {
        background-color: $PRIMARY_HOVERED;
      }
      &:active {
        background-color: $PRIMARY_PRESSED;
      }
    }
    .wrapperFeature {
      @include wrapper(column);
      width: calc(100% - 2rem);
      .inputFeature {
        display: flex;
        width: 100%;
        align-items: flex-end;
        gap: 8px;
        .uploadPhoto {
          display: flex;
          flex-direction: column;
          row-gap: 4px;
          .upload {
            display: flex;
            flex-direction: column;
            border: 1px dashed $NEUTRAL-50;
            border-radius: 8px;
            padding: 1rem;
            row-gap: 12px;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 239px;
            height: 136px;
            > div {
              cursor: pointer;
              display: flex;
              gap: 4px;
              background-color: $PRIMARY_MAIN;
              border-radius: 8px;
              color: $NEUTRAL-10;
              padding: 6px 12px;
              width: fit-content;
              font-weight: 700;
              font-size: 14px;
              align-items: center;
              &:hover {
                background-color: $PRIMARY_HOVERED;
              }
              &:active {
                background-color: $PRIMARY_PRESSED;
              }
              .icon {
                width: 14px;
                height: 14px;
                flex-shrink: 0;
              }
            }
            > span {
              color: $NEUTRAL-50;
              font-size: 12px;
              width: 224px;
            }
          }
          .upload[is-error="true"] {
            border-color: $ALERT_DANGER_MAIN;
            > span {
              color: $ALERT_DANGER_MAIN;
            }
          }
          .upload[is-empty="true"] {
            border-color: $ALERT_DANGER_MAIN;
          }
          > span {
            font-size: 14px;
          }
        }
        .textArea {
          @include textArea();
        }
      }
      .inputFeature[is-choose-file="true"] {
        .uploadPhoto {
          .upload {
            padding: 0 2px;
            > img[alt="color-motor-variant"] {
              width: calc(239px - 1rem);
              height: calc(116px);
              object-fit: contain;
            }
          }
        }
      }
      .header {
        display: flex;
        width: 100%;
        margin: 0;
        padding: 0;
        padding-bottom: 12px;
        > h3 {
          font-size: 24px;
          font-weight: 700;
        }
        .icon {
          width: 24px;
          height: 24px;
          flex-shrink: 0;
          color: $NEUTRAL-60;
          &:hover {
            color: $ALERT_DANGER_MAIN;
          }
        }
      }
      .labelInput {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        label {
          font-size: 14px;
          > span {
            color: $ALERT_DANGER_MAIN;
          }
        }
      }
    }
    .inputSpecification {
      @include wrapper(column);
      > h3 {
        font-size: 24px;
        font-weight: 700;
      }
      .divider {
        height: 1px;
        margin-block: 12px;
        margin-inline: 0;
        width: 100%;
        background: $NEUTRAL-50 !important;
      }
      .input {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        width: 100%;
        > label {
          font-size: 14px;
          color: $NEUTRAL-100;
        }
      }
      .input.optional {
        width: 100%;
        display: flex;
        > label {
          display: flex;
          flex-direction: column;
          row-gap: 4px;
          width: 100%;
          > div {
            display: flex;
            gap: 12px;
            align-items: center;
            .iconDelete {
              width: 24px;
              height: 24px;
              flex-shrink: 0;
              cursor: pointer;
              color: $NEUTRAL-60;
              &:hover {
                color: $ALERT_DANGER_MAIN;
              }
            }
          }
        }
      }
      .threeRow {
        width: 100%;
        display: flex;
        gap: 1rem;
      }
      .addLink {
        @include addButton(white, $PRIMARY_MAIN);
        &:hover {
          background-color: $PRIMARY_SURFACE;
        }
      }
      .checkboxConnect {
        margin: 0 !important;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0;
        .flexCheckBox {
          width: 100%;
          display: flex;
          gap: 8px;
          align-items: center;
          justify-content: flex-start;
          > input[type="checkbox"] {
            width: fit-content;
          }
          .textCheckbox {
            width: 100% !important;
            font-size: 14px;
            color: $NEUTRAL-100;
          }
        }
      }
    }
  }
  .wrapperModalReset {
    padding: 24px 1rem;
    border-radius: 8px;
    background-color: $NEUTRAL-10;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
    .contain {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      align-items: center;
      .iconQuestion {
        width: 60px;
        height: 60px;
      }
      > h1 {
        font-size: 24px;
        font-weight: 700;
      }
      p {
        width: 450px;
        font-size: 14px;
        color: $NEUTRAL-60;
        text-align: center;
      }
    }
    .wrapperButton {
      width: 100%;
      display: flex;
      gap: 10px;
      .buttonModal {
        cursor: pointer;
        width: 100%;
        display: flex;
        padding: 10px 12px;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
      }
      .buttonModal:nth-child(1) {
        border: 1px solid $NEUTRAL-100;
      }
      .buttonModal:nth-child(2) {
        background-color: $PRIMARY_MAIN;
        color: $NEUTRAL-10;
      }
    }
  }
}
