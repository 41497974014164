.bodySidebar {
  height: 100vh;
  // padding: 32px;
  padding: 32px;
  width: 228px;
  height: 100%;
  min-height: 100%;
  // display: flex;
  .sidebarContainer {
    // position: fixed;
    min-height: 816px;
    display: flex;
    width: 226px;
    flex-direction: column;
    gap: 32px;
    padding: 24px 12px;
    border-radius: 16px;
    background: var(--primary-surface, #dee9f4);
    &.sidebarResp {
      border-radius: 16px 0px 0px 16px;
    }
    .imageBox {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 24px 24px 0px 24px;
      .imagesLogo {
        width: 36px;
        height: 36px;
      }
      .imagesTitleLogo {
        width: 60%;
        height: 100%;
      }
    }

    .logoutBox {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 16px 24px;
      .text {
        color: var(--neutral-70, #6d6d6d);

        /* Text L/Regular */
        // font-family: Roboto;
        font-size: calc(10px + (16 - 10) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        text-decoration: none;
      }
    }

    .routesBox {
      display: flex;
      flex-direction: column;
      // gap: 16px;

      .routes {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 16px 24px;
        cursor: pointer;
        text-decoration: none;
        &.routesActive {
          background: rgba(53, 122, 187, 0.3);
          border-radius: 50px;
        }
        .text {
          color: var(--neutral-70, #6d6d6d);

          /* Text L/Regular */
          // font-family: Roboto;
          font-size: calc(10px + (16 - 10) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
          &.textActive {
            color: var(--primary-main, #357abb);
            font-weight: 700;
          }
        }
      }
    }
  }
}

.bodySidebarResponsive {
  display: none;
}

.bodyResponsive {
  display: none;
}

@media (max-width: 1250px) {
  .bodySidebar {
    display: none;
  }
  .bodyResponsive {
    display: flex;
    flex-direction: column;
    height: 100vh;
    // padding: 32px;
    padding: 32px;
    // padding-left: 32px;
    width: 100%;
    position: relative;
    .sidebarContainer {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      padding: 24px;
      border-radius: 16px;
      background: var(--primary-surface, #dee9f4);
      .top {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
      }

      .imageBox {
        display: flex;
        align-items: center;
        gap: 8px;
        // width: 100%;
        // padding: 24px 24px 0px 24px;
        .imagesLogo {
          width: 36px;
          height: 36px;
        }
      }

      .logoutBox {
        display: flex;
        align-items: center;
        gap: 16px;
        // padding: 16px 24px;
        .text {
          color: var(--neutral-70, #6d6d6d);

          /* Text L/Regular */
          // font-family: Roboto;
          font-size: calc(12px + (16 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
          text-decoration: none;
        }
      }

      .routesBox {
        display: flex;
        flex-direction: column;
        // gap: 32px;

        .routes {
          display: flex;
          align-items: center;
          gap: 16px;
          padding: 16px 16px;
          cursor: pointer;
          &.routesActive {
            background: rgba(53, 122, 187, 0.3);
            border-radius: 50px;
          }
          // padding: 16px 24px;
          .text {
            color: var(--neutral-70, #6d6d6d);

            /* Text L/Regular */
            // font-family: Roboto;
            font-size: calc(
              12px + (16 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
          }
        }
      }
    }

    .bodyDropdownSide {
      position: absolute;

      display: flex;
      width: 190px;
      height: 100%;
      flex-direction: column;
      align-items: center;
      // gap: 0px;
      padding: 24px;
      border-radius: 0px 16px 16px 0px;
      background: var(--primary-surface, #dee9f4);
      left: 85%;
      top: 0;
      .top {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
      }

      .iconList {
        display: flex;
        width: 100%;
        height: 28px;
      }
      .imageBox {
        display: flex;
        align-items: center;
        gap: 8px;
        position: relative;
        right: 45px;
        .imagesLogo {
          width: 100px;
          height: 45px;
        }
      }

      .logoutBox {
        position: relative;
        right: 70px;
        bottom: 30px;
        .text {
          color: var(--neutral-70, #6d6d6d);

          /* Text L/Regular */
          // font-family: Roboto;
          font-size: calc(12px + (16 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
        }
      }

      .routesBox {
        display: flex;
        flex-direction: column;
        padding: 32px 0px;
        position: relative;
        right: 40px;
        bottom: 13px;

        .routes {
          cursor: pointer;
          .textIklan {
            color: var(--neutral-70, #6d6d6d);

            /* Text L/Regular */
            // font-family: Roboto;
            font-size: calc(
              12px + (16 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            position: relative;
            bottom: 13px;
          }
          .textServis {
            color: var(--neutral-70, #6d6d6d);

            /* Text L/Regular */
            // font-family: Roboto;
            font-size: calc(
              12px + (16 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            position: relative;
            bottom: 9px;
          }
          .textOnderdil {
            color: var(--neutral-70, #6d6d6d);

            /* Text L/Regular */
            // font-family: Roboto;
            font-size: calc(
              12px + (16 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            position: relative;
            bottom: 5px;
          }
          .textDashbord {
            color: var(--neutral-70, #6d6d6d);

            /* Text L/Regular */
            // font-family: Roboto;
            font-size: calc(
              12px + (16 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            position: relative;
            top: 4px;
          }
          .text {
            color: var(--neutral-70, #6d6d6d);

            /* Text L/Regular */
            // font-family: Roboto;
            font-size: calc(
              12px + (16 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
          }
        }
      }
    }
  }
}
@media (max-width: 900px) {
  .bodySidebar {
    display: none;
  }

  .bodyResponsive {
    display: none;
  }

  .bodDropdown {
    width: 100%;
    height: 100%;
    // background-color: red;
    position: absolute;
    top: 68px;
    .routesDropdown {
      background: #dee9f4;
      backdrop-filter: blur(8px);
      width: 100%;
      // height: 100%;
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 32px;
      .logoutBox {
        display: flex;
        align-items: center;
        gap: 16px;
        // padding: 16px 24px;
        .text {
          color: var(--neutral-70, #6d6d6d);

          /* Text L/Regular */
          // font-family: Roboto;
          font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
        }
      }
      .routesBox {
        display: flex;
        flex-direction: column;
        gap: 24px;
        .routes {
          display: flex;
          align-items: center;
          gap: 16px;
          // padding: 16px 24px;
          .text {
            color: var(--neutral-70, #6d6d6d);

            /* Text L/Regular */
            // font-family: Roboto;
            font-size: calc(
              14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
          }
        }
      }
    }
    .closeDropdown {
      width: 100%;
      height: 50vh;
    }
  }

  .bodySidebarResponsive {
    display: flex;
    flex-direction: column;
    // padding: 16px 16px;
    gap: 16px;
    background: #dee9f4;
    backdrop-filter: blur(8px);
    width: 100%;
    .container {
      padding: 16px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .iconList {
        cursor: pointer;
      }
      .imageBox {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
        // padding: 24px 24px 0px 24px;
        .imagesLogo {
          width: 36px;
          height: 36px;
        }
        .imagesTitleLogo {
          width: 100px;
          height: 10px;
        }
      }
    }
  }
}
