.body {
  padding: 32px 32px 32px 0px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  min-height: 100vh;
}

.box {
  // display: flex;
  border-radius: 8px;
  background: var(--neutral-10, #fff);
  // height: 100%;
  width: 100%;
  padding: 24px;
}

.detailBox {
  display: flex;
  padding: 24px 24px 0px 24px;
  border-top: 1px solid var(--neutral-50, #c7c7c7);
  width: 100%;
  .leftDetail {
    flex: 1;
    height: 580px;
    padding-right: 24px;
    // width: 50%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    span {
      color: var(--neutral-100, #212121);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
    .imagesBox {
      display: flex;
      justify-content: center;
      border-radius: 8px;
      background: #fff;
      border: 1px solid var(--neutral-50, #c7c7c7);
      width: 100%;
      img {
        width: 60%;
      }
    }
  }
  .rightDetail {
    width: 50%;
    padding-left: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
    border-left: 1px solid var(--neutral-50, #c7c7c7);

    .detail {
      display: flex;
      flex-direction: column;
      gap: 8px;
      span {
        color: var(--neutral-100, #212121);

        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
      label {
        color: var(--neutral-100, #212121);

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        text-align: justify;
      }

      .statusRed {
        padding: 4px 8px;
        display: flex;
        width: 30%;
        justify-content: center;
        border-radius: 8px;
        border: 1px solid var(--alert-success-main, #d1001c);
        background: var(--neutral-10, #fff);
        color: #d1001c;
      }
      .statusGreen {
        padding: 4px 8px;
        display: flex;
        width: 30%;
        justify-content: center;
        border-radius: 8px;
        border: 1px solid var(--alert-success-main, #65d072);
        background: var(--neutral-10, #fff);
        color: #65d072;
      }
    }
  }
}

// @media (max-width: 900px) {
//   .body {
//     padding: 32px 16px;
//   }

//   .detailBox {
//     .rightDetail {
//       .detail {
//         .status {
//           width: 50%;
//         }
//       }
//     }
//   }
// }

@media (max-width: 900px) {
  .body {
    padding: 32px 16px;
  }

  .detailBox {
    flex-direction: column;
    gap: 32px;
    .leftDetail {
      border: none;
      height: 100%;
      width: 100%;
      padding-right: 0px;
    }
    .rightDetail {
      width: 100%;
      padding-left: 0px;
      border-left: 0;
    }
  }
}
