.backAlert {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background: rgba(0, 0, 0, 0.5);
}
.bodyAlert {
  display: flex;
  width: 40%;
  padding: 24px;
  margin: 20% auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: var(--Neutral-10, #fff);
  .iconSuccess {
    color: #357abb;
    border-radius: 100%;
    border: solid 4px;
    padding: 5px;
  }
  .iconError {
    color: #842029;
    border-radius: 100%;
    border: solid 4px;
    padding: 5px;
  }
  .iconProsess {
    animation: berubah 2s linear infinite alternate;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    // margin-bottom: 10px;
  }
  @keyframes berubah {
    0% {
      background-color: #357abb;
      left: 0px;
      top: 0px;
    }
    25% {
      background-color: #b6d4fe;
      left: 200px;
      top: 0px;
    }
    50% {
      background-color: #b6effb;
      left: 200px;
      top: 200px;
    }
    75% {
      background-color: #cfe2ff;
      left: 0px;
      top: 200px;
    }
    100% {
      background-color: #cff4fc;
      left: 0px;
      top: 0px;
    }
  }
  .ketProsess {
    color: var(--Neutral-100, #212121);
    text-align: center;

    /* H4/Bold */
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 116.667% */
  }
}
