@import "../../../theme/variable.scss";

h3 {
  margin: 0;
}

.tableContainer {
  overflow: auto;
  min-height: 300px;
  .placeholder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    position: relative;
    img {
      width: 60px;
      height: 100px;
      max-inline-size: 100%;
      block-size: auto;
    }

    span {
      font-size: 16px;
      color: #adb5bd;
      position: relative;
      font-size: 24px;
      font-weight: 700;
    }
  }

  table {
    border-collapse: collapse;
    border-color: $NEUTRAL-50;
    width: calc(100% + 20%);
    font-size: calc(10px + (16 - 10) * ((100vw - 280px) / (1600 - 280)));
    color: $NEUTRAL-100;
    border-top: 1px solid var(--Neutral-30, $NEUTRAL-50);
    border-bottom: 1px solid var(--Neutral-30, $NEUTRAL-50);
    &.salesTable {
      width: 100%;
    }
    .stickyColumn {
      position: sticky;
      left: 0;
      z-index: 1;
      background-color: #ffffff;
      border-right: 1px solid $NEUTRAL-50;
    }
    .stickyColumn:hover {
      background-color: #d2f3f8;
    }

    tbody {
      .hovered {
        background-color: #d2f3f8;
      }
    }

    .column {
      display: flex;
      align-items: center;
      height: 30px;
      span {
        svg {
          margin-top: 6px;
        }
      }
    }
  }
  table[is-promo="true"] {
    width: 100%;
  }
}
.tableContainer {
  overflow-x: auto;
}
.tableContainer::-webkit-scrollbar {
  display: none;
}
.tableContainer::-ms-scrollbar {
  display: none;
}
.tableContainer::-moz-scrollbar {
  display: none;
}
.tableContainer::-o-scrollbar {
  display: none;
}
.tableContainer {
  scrollbar-width: 2px;
  scrollbar-color: $NEUTRAL-50 transparent;
}
.tableContainer::-webkit-scrollbar-track {
  background-color: transparent;
}
.tableContainer::-moz-scrollbar-track {
  background-color: transparent;
}
.tableContainer::-ms-scrollbar-track {
  background-color: transparent;
}
.tableContainer::-o-scrollbar-track {
  background-color: transparent;
}
.tableContainer::-webkit-scrollbar-thumb {
  background-color: $NEUTRAL-50;
}
.tableContainer::-ms-scrollbar-thumb {
  background-color: $NEUTRAL-50;
}
.tableContainer::-moz-scrollbar-thumb {
  background-color: $NEUTRAL-50;
}
.tableContainer::-o-scrollbar-thumb {
  background-color: $NEUTRAL-50;
}
.body {
  font-size: 14px;
  min-height: 460px;
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 460px;
    height: 100%;
  }
}

table th {
  padding: 12px;
}

table tr:last-child {
  border-bottom: unset;
  padding: 12px;
}

table td,
table tr {
  border-top: 0px;
  border-bottom: 0px;

  padding: 16px;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: $NEUTRAL-100;
  font-size: calc(14em / 14);
  font-weight: 500;
  cursor: pointer;
  border-top: 1px solid $NEUTRAL-50;
  border-bottom: 1px solid $NEUTRAL-50;
}

.header {
  width: 100%;
  height: 76px;
  //   border-bottom: 1px solid $NEUTRAL-30;
  .control {
    padding: 24px 16px 0px 16px;
  }
  .filter {
    h3 {
      font-size: calc(20em / 14);
      font-weight: 700;
    }
  }
}

// master
.radio {
  height: 24px;
  width: 24px;
  border-radius: 12px;
  border: 1px solid #adb5bd;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  .activeRadio {
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background-color: #1dc1dd;
  }
}

@media (width <= 1100px) {
  .tableContainer {
    table {
      // width: 100%;
      font-size: 12px;

      .stickyColumn {
        width: 50px;
        min-width: 50px;
      }
    }
  }
}

@media (width <= 965px) {
  .tableContainer {
    table {
      // width: 100%;
      font-size: 10px;
    }
  }
}

@media (width <= 768px) {
  .tableContainer {
    table {
      // width: 100%;
      // font-size: 10px;

      .stickyColumn {
        max-width: 150px;
        // background-color: red;
      }
    }
  }
}

@media (width <= 420px) {
  .tableContainer {
    .placeholder {
      span {
        width: 100px;
        font-size: 14px;
      }
    }
    table {
      width: 150%;
      font-size: 12px;

      tr > :first-of-type {
        width: 150px;
      }
    }
  }
}
