.container {
  display: flex;
  justify-content: center;
  gap: 64px;
  // flex-direction: column;
  // background: var(--background, #eef3f8);
  // padding: 24px;
  // min-height: 100vh;
  // width: 100%;
  // .sidebar {
  //   width: 400px;
  // }

  // > *:nth-child(2) {
  //   // flex-grow: 1;
  //   width: 100%;
  //   // margin-top: 80px;
  // }
}

@media (max-width: 1250px) {
  .container {
    //   flex-direction: column;
    // min-height: 100vh;
    .sidebar {
      width: 15%;
    }
  }
}

@media (max-width: 900px) {
  .container {
    flex-direction: column;
    .sidebar {
      width: 100%;
      position: sticky;
      top: 0;
      z-index: 5;
    }
    // min-height: 100vh;
  }
}
