.isAvailable {
  display: flex;
  padding: 4px 8px;
  color: #65d072;
  border-radius: 8px;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #65d072;
  width: 88px;
  > span {
    font-size: 12px;
    font-weight: 500;
  }
}
.isAvailable[is-true="true"] {
  color: #65d072;
  border: 1px solid #65d072;
}
.isAvailable[is-true="false"] {
  color: #d1001c;
  border: 1px solid #d1001c;
}
