.buttonSection {
  display: flex;
  justify-content: center;
  margin: 40px;
  button {
    width: 200px;
    margin: 20px 10px;
    padding: 15px 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    color: black;
    font-family: Roboto;
    background: transparent;
    border: 1px solid #212121;
    position: relative;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
  }
  button:hover {
    border: 2px solid #212121;
  }
}

.boxContent button {
  // border: none;
  font-size: 13px;
  background-color: white;
  border: 1px solid rgb(11, 12, 13);
  color: black;
  font-family: Roboto;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.boxContent button span {
  position: relative;
  left: 5%;
}

.boxContent button:hover {
  background-color: #357abb;
  color: white;
}

.buttonAdmin {
  display: flex;
  gap: 10px;
  // background: red;
  // width: 100%;
  // text-align: right;
  float: right;
  text-align: center;

  .buttonAdminSubmit {
    background: #357abb;
    border: none;
    border-radius: 8px;
    color: white;
    font-family: Roboto;
    font-size: 12px;
    // padding: 12px 10px;
    font-weight: bold;
    cursor: pointer;
    width: 100px;
    padding: 10px 12px;
  }

  .buttonAdminSubmit:hover {
    background: white;
    border: 1px solid black;
    color: black;
  }

  .buttonAdminCancel {
    border-radius: 8px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    background: white;
    border: 1px solid black;
    color: black;
    cursor: pointer;
    width: 100px;
    padding: 10px 12px;
  }

  .buttonAdminCancel:hover {
    background: #357abb;
    border: 1px solid black;
    color: white;
  }
}

.buttonAdmin button {
  background: #357abb;
  border: none;
  border-radius: 8px;
  color: white;
  font-family: Roboto;
  font-size: 12px;
  // padding: 12px 10px;
  font-weight: bold;
  cursor: pointer;
  width: 100px;
  padding: 10px 12px;
}

.buttonAdmin button:hover {
  background: white;
  border: 1px solid black;
  color: black;
}

.buttonAdmin a {
  border-radius: 8px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  background: white;
  border: 1px solid black;
  color: black;
  cursor: pointer;
  width: 100px;
  padding: 10px 12px;
  // align-items: center;
  // text-align: center;
}

.buttonAdmin a:hover {
  background: #357abb;
  border: none;
  color: white;
  font-weight: bold;
}
