@import "../../../../theme/variable.scss";
@mixin row() {
  display: flex;
  flex-direction: column;
}

.wrapperAddPromo {
  padding: 32px 32px 32px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  // max-width: 1116px;

  .mainContent {
    padding: 24px;
    border-radius: 8px;
    background-color: $NEUTRAL-10;
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    .header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 1rem;
      align-items: center;
      .buttonSave {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 12px 1rem;
        background-color: $PRIMARY_MAIN;
        color: $NEUTRAL-10;
        border-radius: 8px;
        font-weight: 700;
        cursor: pointer;
        &:hover {
          background-color: $PRIMARY_HOVERED;
        }
      }
      .buttonSave[is-disabled="true"] {
        cursor: not-allowed;
      }
      .between {
        display: flex;
        gap: 1rem;
        align-items: center;
        .iconHeader {
          padding: 4px;
          display: flex;
          align-items: center;
          .icon {
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            cursor: pointer;
            transform: rotate(180deg);
          }
        }
        > h1 {
          font-size: 24px;
          font-weight: 700;
        }
      }
      .wrapperCta {
        display: flex;
        align-items: center;
        gap: 1rem;
        .btn {
          display: flex;
          gap: 4px;
          align-items: center;
          cursor: pointer;
          .icon {
            flex-shrink: 0;
            width: 20px;
            height: 20px;
          }
        }
        .btn:nth-child(1) {
          color: $ALERT_DANGER_MAIN;
        }
        .btn:nth-child(2) {
          padding: 4px;
          border: 1px solid $PRIMARY_PRESSED;
          border-radius: 4px;
          color: $PRIMARY_PRESSED;
          &:hover {
            background-color: $NEUTRAL-20;
          }
        }
        .btn:nth-child(3) {
          padding: 4px 16px;
          border: 1px solid $PRIMARY_MAIN;
          border-radius: 4px;
          color: $NEUTRAL-10;
          background-color: $PRIMARY_MAIN;
          &:hover {
            background-color: $PRIMARY_HOVERED;
          }
          .icon {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .divider {
      height: 1px;
      width: 100%;
      background-color: $NEUTRAL-50;
    }
    .inputField {
      @include row();
      row-gap: 12px;
      .labelInput {
        display: flex;
        align-items: center;
        gap: 106px;
        > label {
          width: 150px;
          font-size: 14px;
        }
        .twoInput {
          width: 100%;
          display: flex;
          gap: 12px;
        }
      }
    }
    .wrapperModalAddPromo {
      border-radius: 8px;
      background-color: $NEUTRAL-10;
      padding: 31px 18px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      position: sticky;
      top: 0;
      .header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        > h2 {
          font-size: 24px;
          font-weight: 700;
        }
        > .icon {
          cursor: pointer;
          width: 24px;
          height: 24px;
          color: $NEUTRAL-100;
          flex-shrink: 0;
        }
      }
      .checkboxAndfilter {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        div:nth-child(1) {
          display: flex;
          align-items: center;
          gap: 4px;
          font-size: 14px;
          input[type="checkbox"] {
            width: 18px;
            height: 18px;
          }
          .checkboxIcon {
            cursor: pointer;
            flex-shrink: 0;
          }
        }
        div:nth-child(2) {
          display: flex;
          align-items: center;
          gap: 4px;
          font-size: 14px;
          .icon {
            cursor: pointer;
            color: $NEUTRAL-100;
            width: 20px;
            height: 20px;
          }
        }
      }
      .wrapperCardList {
        display: flex;
        flex-wrap: wrap;
        height: 430px;
        overflow: scroll;

        .card {
          cursor: pointer;
          width: 247px;
          height: fit-content;
          padding: 10px;
          border: 1px solid $NEUTRAL-30;
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          // overflow: hidden;
          .header {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 0;
            > div {
              display: flex;
              width: 100%;
              justify-content: space-between;
              > span {
                padding: 0;
                display: -webkit-box;
                display: -moz-box;
                -webkit-box-orient: vertical;
                -moz-box-orient: vertical;
                line-clamp: 1;
                -webkit-line-clamp: 1;
                overflow: hidden;
                font-size: 1rem;
                font-weight: 700;
                line-height: 24px;
              }
              .checkboxIcon {
                flex-shrink: 0;
                cursor: pointer;
              }
            }
            .type {
              color: $NEUTRAL-50;
              font-size: 14px;
            }
          }
          .image {
            display: flex;
            justify-content: center;
            img[alt="list-product"] {
              transition: 0.5s;
              width: 131.201px;
              height: 113px;
              // transform: translateX(-200px);
            }
          }
          .price {
            font-size: 20px;
            font-weight: 700;
          }
          &:hover {
            .image {
              img[alt="list-product"] {
                transform: scale(1.2);
                // transform: translateX(0);
              }
            }
          }
          &:hover {
            background-color: $PRIMARY_SURFACE;
          }
        }
      }
      .buttonListProduct {
        background-color: $NEUTRAL-10;
        position: sticky;
        bottom: 1rem;
        display: flex;
        gap: 20px;
        width: 100%;
        .btn {
          cursor: pointer;
          display: flex;
          justify-content: center;
          width: 100%;
          border: 1px solid $NEUTRAL-50;
          border-radius: 8px;
          padding: 12px 1rem;
          > span {
            font-weight: 500;
          }
          &:hover {
            background-color: $NEUTRAL-30;
          }
        }
        .btn:nth-child(2) {
          background-color: $PRIMARY_MAIN;
          color: $NEUTRAL-10;
          &:hover {
            background-color: #21598f;
          }
        }
      }
    }
  }
}
.box {
  border: 1px solid $NEUTRAL-50;
  border-radius: 8px;
  .headerTitle {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    > h1 {
      font-size: 24px;
    }
    .addItems {
      display: flex;
      gap: 8px;
      align-items: center;
      cursor: pointer;
      > div {
        display: flex;
        padding: 4px;
        background-color: $PRIMARY_MAIN;
        border-radius: 4px;
      }
      > span {
        font-size: 14px;
        color: $NEUTRAL-100;
      }
    }
  }
}
.pagination {
  padding: 24px;
  display: flex;
  width: 100%;
  .paginationLeft {
    display: flex;
    width: 100%;
  }
  .paginationRight {
    display: flex;
  }
}
