@import "../../../theme/variable.scss";
.container {
  position: relative;
  width: 100%;
}
.input::-webkit-calendar-picker-indicator {
  width: 24px;
  height: 24px;
  display: none;
}
input[type="date"] {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #c2c2c2;
  color: transparent;
  padding-inline: 8px;
  font-size: 1rem;
  position: relative;
  &:focus {
    outline: 1px solid #1dc1dd;
    background-color: white;
    color: #000000;
  }
  &::before {
    position: absolute;
    content: attr(data-text);
    font-size: 14px;
    color: $NEUTRAL-80;
  }
  &:focus::before {
    content: "";
  }
}

input[type="date"][isselected="true"] {
  color: black;
  &::before {
    content: "";
  }
}

.icon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
}
@media (max-width: 380px) {
  input[type="date"],
  input[type="time"] {
    font-size: 14px;
  }
}
